<script setup lang="ts">
  import useCpBem from '~/composables/useCpBem';
  import type {ICpRouteLocationNamedRaw} from '~/utils/services/screens';

  defineProps<{
    href?: string | ICpRouteLocationNamedRaw;
    target?: string
  }>();
  const {b} = useCpBem('cp-pressable');
  const emit = defineEmits<{
    (e: 'click', event: Event): void,
  }>();
</script>

<template>
    <NuxtLink
        v-if="href"
        :class="b"
        :to="href"
        :target="target"
        :prefetch="false"
    >
        <slot />
    </NuxtLink>
    <button
        v-else
        :class="b"
        @click="emit('click', $event)"
    >
        <slot>
            Child
        </slot>
    </button>
</template>

<style scoped lang="scss">
  .cp-pressable {
    text-decoration: none;
    flex: 1;
  }
</style>
